<template>
<div>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
      :baseroute="baseroute"
      :btn-custom="true"
      :data-detail="dataStaffRikmatek"
    />
  </b-card>
  <b-card title="Tim RIKMATEK">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols-md="4"
              label="Nama Staff"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Staff"
                rules="required"
              >
                <v-select
                  v-model="staffRikmatek.staff"
                  label="name"
                  :options="opStaffRikmatek"
                  placeholder="Select Nama Staff"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              label="Jabatan TIM RIKMATEK"
            >
              <validation-provider
                #default="{ errors }"
                name="Jabatan TIM RIKMATEK"
                rules="required"
              >
                <b-form-input
                  v-model="staffRikmatek.job_title"
                  :plaintext="false"
                  placeholder="Inputkan Jabatan"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="4"
              label=""
            >
              <b-button
                class="mr-1"
                variant="warning"
                size="sm"
                type="button"
                @click.prevent="resetStaffRikmatek"
              >
                <span>Reset</span>
              </b-button>
              <b-button
                class="mr-1"
                variant="primary"
                size="sm"
                type="submit"
                @click.prevent="addStaffRikmatekForm"
              >
                <span>Tambah</span>
              </b-button>
            </b-form-group>
          </b-col>
          <br>
        </b-row>
      </b-form>
    </validation-observer>
    <br>
    <b-table
      striped
      responsive
      :items="dataStaffRikmatek"
      :fields="tableRikmatek"
    >
      <template #cell(_)="data">
        <span
          class="mr-1"
        >
          <b-link @click="confirmDelete(data)">
            <b-badge variant="danger">
              <feather-icon icon="TrashIcon" />
            </b-badge>
          </b-link>
        </span>
      </template>
      <template #cell(section)="data">
        {{data.item.section == 'direction'?'Direksi':'Keuangan'}}
      </template>
      <template #cell(file_ttd)="data">
        {{ getTextFile(data.item.file_ttd) }}
      </template>
    </b-table>
  </b-card>
</div>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BFormCheckbox} from 'bootstrap-vue'
import FormFile from '@/views/base/form/FormFile.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BCard,
    BForm, 
    BRow, 
    BCol, 
    BTable, 
    BLink, 
    BBadge, 
    BFormGroup, 
    BFormSelect, 
    BFormInput, 
    BButton,
    FormFile,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {   
      title:'Region',
      dataurl:'/region',
      baseroute:'region',
      fields:[
        { key: 'code', label: 'Kode Regional', type: 'input', rules:'required' , placeholder:'Kode Regional'},
        { key: 'name', label: 'Nama', type: 'input', rules:'required' , placeholder:'Nama'},
        { key: 'address', label: 'Alamat', type: 'autocomplate',rules:'required' , placeholder:'Alamat'},
        { type:'map', label:'Map'},
        { key: 'leader', label: 'Pimpinan', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select Pimpinan'},
        { key: 'pic', label: 'PIC Operasional', type: 'select2', rules:'',dataurl:'/staff', placeholder:'Select PIC Operasional'},
        { key: 'rikmateks', type: 'details'}
        // { label: 'Lokasi', type: 'map', rules:''},
        // { key: 'is_headoffice',label: 'Kantor pusat', type: 'bool', rules:''},
      ],
      opStaffRikmatek: [],
      staffRikmatek: {
        staff_id: null,
        staff: null,
        job_title: "",
      },
      tableRikmatek:[
        { key: 'staff.name', label: 'Nama Staff'},
        { key: 'job_title', label: 'Jabatan TIM RIKMATEK'},
        '_'
      ],
      dataStaffRikmatek: [],
    }
  },
  created() {
    this.getData()
    if(this.$route.params.id != null){
      this.$http.get(this.dataurl+'/'+this.$route.params.id).then(res=>{
        if(res.data.rikmateks)
          this.dataStaffRikmatek = res.data.rikmateks
      })
    }
  },
  methods:{
    getData(){
      this.$http.get('staff?length=').then(res => {
        this.opStaffRikmatek = res.data.data
      })
    },
    resetStaffRikmatek(){
      this.staffRikmatek = {
        staff_id: null,
        staff: null,
        job_title: "",
      }
      this.$refs.simpleRules.reset()
    },
    addStaffRikmatekForm() {
      this.$refs.simpleRules.validate().then( res => {
        if(res){
          this.staffRikmatek.staff_id = this.staffRikmatek.staff.id
          this.dataStaffRikmatek.push(this.staffRikmatek)
          this.resetStaffRikmatek()
          this.$refs.simpleRules.reset()
        }else{
          this.$bvToast.toast('Please correct form input before add staff rikmatek', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    confirmDelete(data){
      this.dataStaffRikmatek.splice(data.index, 1)
    },
  },
}
</script>